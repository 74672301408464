<template>
  <div class="apartment-detail">
    <app-detail :opt="opt"></app-detail>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        id: "",
        title: "订单详情",
        form: [
          {
            label: "订单编号",
            key: "orderNo",
          },
          {
            label: "下单日期",
            key: "payRentalTerm",
            isCol50: true,
            isWidth67: true
          },
          {
            label: "用户名",
            key: "payUserName",
            isCol50: true,
            isWidth67: true
          },
          {
            label: "缴费时间",
            key: "payDate",
            isCol50: true,
            isWidth67: true
          },

          {
            label: "手机号",
            key: "payPhone",
            isCol50: true,
            isWidth67: true
          },
          {
            label: "入住开始时间",
            key: "rentStartDate",
            filters: {
              key: "orderType",
              value: "3"
            },
            isCol50: true,
            isWidth67: true
          },
          {
            label: "入住截止时间",
            key: "rentEndDate",
            filters: {
              key: "orderType",
              value: "3"
            },
            isCol50: true,
            isWidth67: true
          },
          {
            label: "入住时长",
            key: "hours",
            filters: {
              key: "orderType",
              value: "3"
            },
            isCol50: true,
            isWidth67: true
          },
          {
            label: "订单名称",
            key: "orderName",
            isCol50: true,
            isWidth67: true
          },
          {
            label: "订单状态",
            key: "payState",
            isCol50: true,
            isWidth67: true
          },
          {
            label: "押金",
            key: "depositFee",
            isCol50: true,
            isWidth67: true
          },
          {
            label: "租金",
            key: "rentalFee",
            isCol50: true,
            isWidth67: true
          },
          {
            label: "物业费",
            key: "otherFee",
            filters: {
              key: "orderType",
              value: "0"
            },
            isCol50: true,
            isWidth67: true
          },
          // 钟点房不展示13
           {
            label: "电费",
            key: "electric",
            filters: {
              key: "isShow",
              value: "1"
            },
            isCol50: true,
            isWidth67: true
          },
           {
            label: "冷水费",
            key: "coldWater",
            filters: {
              key: "isShow",
              value: "1"
            },
            isCol50: true,
            isWidth67: true
          },
           {
            label: "热水费",
            key: "hotWater",
            filters: {
              key: "isShow",
              value: "1"
            },
            isCol50: true,
            isWidth67: true
          },
          {
            label: "总金额",
            key: "orderAmount",
            isCol50: true,
            isWidth67: true
          },
          {
            label: "退款备注",
            filters: {
              key: "isShow",
              value: "1"
            },
            key: "remark",
            type: "textarea"
          }
        ],
        get(opt) {
          let dto = {
            orderNo: _this.$route.params.id
          };
          _this.post("finance-service/leaseOrder/searchOrderDetail", dto).then(data => {
              data.orderTypeStr = ["长租", "短租",'','钟点房'][data.orderType];
              data.payDate = data.payDate ? _this.format(data.payDate) : "";
              data.payPhone = data.payPhone? data.payPhone.substring(0, 3) + "****" +
                  data.payPhone.substring(7, data.payPhone.length) : "";
              data.payState = [ "未支付", "待支付", "已支付", "支付失败", "订单取消", "已失效", "取消预订"][data.payState - 1];
              data.isShow = data.orderType == 3? 0 : 1
              if(data.orderType == 1) {
                // 短租
                data.payRentalTerm = _this.format(data.createdDate,1);
                data.rentalFee = "￥" + (data.daysApart?data.daysApart:'0.00');
              }else if(data.orderType == 3) {
                data.payRentalTerm = _this.format(data.createdDate,1);
                data.rentalFee = "￥" + (data.rentalFee?data.rentalFee:'0.00');
              }else {
                // 长租
                data.rentalFee = "￥" + (data.rentalFee?data.rentalFee:'0.00');
                data.payRentalTerm = data.payRentalTerm ? data.payRentalTerm : '';
              }
              data.depositFee = "￥" + (data.depositFee?data.depositFee:'0.00');
              data.orderAmount = "￥" + (data.orderAmount?data.orderAmount:'0.00');
              data.otherFee = "￥" + (data.otherFee?data.otherFee:'0.00');
              data.propertyFee = "￥" + (data.propertyFee?data.propertyFee:'0.00');
              if(data.waterElectrics && data.waterElectrics[0]){
              data.electric = data.waterElectrics[0].electric?("￥" + data.waterElectrics[0].electric):'';
              data.coldWater = data.waterElectrics[0].coldWater?("￥" + data.waterElectrics[0].coldWater):'';
              data.hotWater = data.waterElectrics[0].hotWater?("￥" + data.waterElectrics[0].hotWater):'';
              }
              opt.cb(data);
            });
        }
      }
    };
  },
  created() {
    this.id = this.$route.params.id || "";
    console.log("apartment-detail created!!");
  }
};
</script>

